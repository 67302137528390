const registrationModel = [
  {
    element: "text",
    name: "vorname",
    label: { de: "Vorname", en: "First Name", fr: "Prénom", it: "Nome" },
    type: "textInput",
    half: true,
    defaultValue: "",
    id: "name-index",
    helperText: " ",
    minLength: 2,
    validation: "lengthError",
    error: false,
  },
  {
    element: "text",
    name: "nachname",
    label: { de: "Nachname", en: "Last Name", fr: "Nom", it: "Cognome" },
    type: "textInput",
    half: true,
    defaultValue: "",
    id: "lastname-index",
    helperText: " ",
    minLength: 2,
    validation: "lengthError",
    error: false,
  },

  {
    element: "text",
    name: "strasse",
    label: { de: "Strasse", en: "Street/No.", fr: "Rue/No", it: "Via/N." },
    type: "textInput",
    half: true,
    defaultValue: "",
    id: "street-index",
    helperText: " ",
    minLength: 2,
    validation: "lengthError",
    error: false,
  },

  {
    element: "number",
    name: "telefon",
    label: { de: "Telefon", en: "Phone", fr: "Téléphone", it: "Telefono" },
    type: "textInput",
    half: true,
    id: "phone-index",
    defaultValue: "",
    helperText: " ",
    minLength: 10,
    validation: "phoneError",
    error: false,
  },

  {
    element: "email",
    name: "email",
    label: { de: "E-Mail", en: "E-Mail", fr: "E-Mail", it: "E-Mail" },
    type: "textInput",
    half: true,
    id: "email-index",
    defaultValue: "",
    helperText: " ",
    validation: "emailError",
    error: false,
  },

  {
    element: "text",
    name: "geburtsdatum",
    label: {
      de: "Geburtsdatum",
      en: "Date of birth",
      fr: "Date de naissance",
      it: "Data di nascita",
    },
    type: "datePicker",
    half: true,
    id: "bdate-index",
    defaultValue: "",
    helperText: " ",
    validation: "dateError",
    error: false,
  },

  {
    element: "select",
    name: "persons",
    label: {
      de: "Anzahl Personen im Haushalt",
      en: "Number of persons in household",
      fr: "Nombre de personnes dans le ménage",
      it: "Numero di persone in famiglia",
    },
    type: "inputFullWidth",
    id: "persons-index",
    defaultValue: "",
    helperText: " ",
    validation: "selectError",
    error: false,
    options: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
    ],
  },
]

export default registrationModel
