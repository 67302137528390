module.exports = {
  AG: {
    KINDER: {
      Hausarzt: {
        0: "77.70",
        100: "71.90",
        200: "66.10",
        300: "60.20",
        400: "54.40",
        500: "48.60",
        600: "47.00",
      },
      Telmed: {
        0: "81.70",
        100: "78.80",
        200: "70.90",
        300: "65.60",
        400: "60.30",
        500: "54.80",
        600: "49.50",
      },
      HMO: {
        0: "72.90",
        100: "67.10",
        200: "61.30",
        300: "55.40",
        400: "49.60",
        500: "47.00",
        600: "47.00",
      },
      Standard: {
        0: "87.30",
        100: "81.50",
        200: "75.70",
        300: "69.80",
        400: "64.00",
        500: "58.20",
        600: "52.30",
      },
      maxPrice: {
        0: "134.10",
        100: "115.10",
        200: "110.40",
        300: "103.40",
        400: "98.70",
        500: "97.20",
        600: "87.00",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "234.60",
        500: "223.60",
        1000: "195.80",
        1500: "168.10",
        2000: "140.50",
        2500: "137.20",
      },
      Telmed: {
        300: "234.60",
        500: "223.60",
        1000: "195.80",
        1500: "168.10",
        2000: "140.50",
        2500: "137.20",
      },
      HMO: {
        300: "222.90",
        500: "211.90",
        1000: "184.20",
        1500: "156.40",
        2000: "137.20",
        2500: "137.20",
      },
      Standard: {
        300: "260.60",
        500: "249.60",
        1000: "221.90",
        1500: "194.10",
        2000: "166.50",
        2500: "138.70",
      },
      maxPrice: {
        300: "403.40",
        500: "381.85",
        1000: "354.60",
        1500: "331.20",
        2000: "303.95",
        2500: "276.65",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "357.40",
        500: "346.70",
        1000: "319.50",
        1500: "292.30",
        2000: "265.30",
        2500: "238.10",
      },
      Telmed: {
        300: "352.70",
        500: "342.50",
        1000: "317.10",
        1500: "290.70",
        2000: "263.60",
        2500: "236.50",
      },
      HMO: {
        300: "335.30",
        500: "324.50",
        1000: "297.40",
        1500: "270.20",
        2000: "243.20",
        2500: "216.00",
      },
      Standard: {
        300: "383.40",
        500: "372.30",
        1000: "344.70",
        1500: "316.90",
        2000: "289.20",
        2500: "261.50",
      },
      maxPrice: {
        300: "477.10",
        500: "466.40",
        1000: "439.20",
        1500: "412.00",
        2000: "385.00",
        2500: "357.80",
      },
    },
  },
  AI: {
    KINDER: {
      Hausarzt: {
        0: "63.50",
        100: "58.10",
        200: "52.70",
        300: "47.30",
        400: "41.90",
        500: "38.40",
        600: "36.80",
      },
      Telmed: {
        0: "62.80",
        100: "57.40",
        200: "52.00",
        300: "46.50",
        400: "41.20",
        500: "37.90",
        600: "36.30",
      },
      HMO: {
        0: "63.50",
        100: "58.10",
        200: "52.70",
        300: "47.30",
        400: "41.90",
        500: "38.40",
        600: "36.80",
      },
      Standard: {
        0: "69.00",
        100: "65.20",
        200: "59.30",
        300: "52.30",
        400: "46.80",
        500: "41.20",
        600: "38.10",
      },
      maxPrice: {
        0: "111.00",
        100: "87.60",
        200: "85.70",
        300: "79.70",
        400: "78.70",
        500: "83.30",
        600: "64.00",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "211.40",
        500: "201.60",
        1000: "177.10",
        1500: "152.60",
        2000: "132.20",
        2500: "125.20",
      },
      Telmed: {
        300: "210.60",
        500: "200.50",
        1000: "175.00",
        1500: "149.10",
        2000: "126.40",
        2500: "124.30",
      },
      HMO: {
        300: "209.00",
        500: "199.20",
        1000: "174.80",
        1500: "150.20",
        2000: "125.20",
        2500: "125.20",
      },
      Standard: {
        300: "229.00",
        500: "217.80",
        1000: "190.20",
        1500: "162.50",
        2000: "137.40",
        2500: "125.30",
      },
      maxPrice: {
        300: "333.40",
        500: "322.70",
        1000: "295.70",
        1500: "268.30",
        2000: "241.30",
        2500: "220.10",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "269.20",
        500: "258.40",
        1000: "232.90",
        1500: "206.60",
        2000: "181.10",
        2500: "167.30",
      },
      Telmed: {
        300: "283.00",
        500: "272.30",
        1000: "245.10",
        1500: "217.90",
        2000: "190.90",
        2500: "172.50",
      },
      HMO: {
        300: "265.90",
        500: "255.10",
        1000: "229.70",
        1500: "203.30",
        2000: "177.90",
        2500: "167.30",
      },
      Standard: {
        300: "311.00",
        500: "300.30",
        1000: "273.10",
        1500: "245.90",
        2000: "218.90",
        2500: "191.70",
      },
      maxPrice: {
        300: "413.80",
        500: "403.00",
        1000: "375.90",
        1500: "348.70",
        2000: "321.60",
        2500: "294.50",
      },
    },
  },
  AR: {
    KINDER: {
      Hausarzt: {
        0: "72.90",
        100: "69.60",
        200: "63.80",
        300: "58.00",
        400: "53.20",
        500: "47.90",
        600: "43.40",
      },
      Telmed: {
        0: "74.60",
        100: "68.80",
        200: "63.00",
        300: "57.90",
        400: "53.20",
        500: "47.90",
        600: "45.10",
      },
      HMO: {
        0: "72.90",
        100: "69.60",
        200: "63.80",
        300: "58.00",
        400: "53.20",
        500: "47.90",
        600: "43.40",
      },
      Standard: {
        0: "81.40",
        100: "78.90",
        200: "70.00",
        300: "63.90",
        400: "58.10",
        500: "52.22",
        600: "46.40",
      },
      maxPrice: {
        0: "125.70",
        100: "118.10",
        200: "112.30",
        300: "106.50",
        400: "100.60",
        500: "94.80",
        600: "89.00",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "246.00",
        500: "235.20",
        1000: "207.90",
        1500: "180.60",
        2000: "153.40",
        2500: "137.00",
      },
      Telmed: {
        300: "240.90",
        500: "230.10",
        1000: "202.80",
        1500: "175.50",
        2000: "148.20",
        2500: "137.00",
      },
      HMO: {
        300: "238.40",
        500: "227.50",
        1000: "200.20",
        1500: "172.90",
        2000: "145.70",
        2500: "137.00",
      },
      Standard: {
        300: "256.20",
        500: "245.40",
        1000: "217.90",
        1500: "190.20",
        2000: "162.50",
        2500: "137.10",
      },
      maxPrice: {
        300: "378.15",
        500: "342.70",
        1000: "315.00",
        1500: "287.50",
        2000: "260.40",
        2500: "234.20",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "319.30",
        500: "308.50",
        1000: "283.20",
        1500: "257.10",
        2000: "231.90",
        2500: "207.60",
      },
      Telmed: {
        300: "318.80",
        500: "307.80",
        1000: "280.40",
        1500: "255.20",
        2000: "231.60",
        2500: "205.70",
      },
      HMO: {
        300: "315.40",
        500: "304.60",
        1000: "279.40",
        1500: "253.20",
        2000: "228.10",
        2500: "206.80",
      },
      Standard: {
        300: "351.60",
        500: "340.50",
        1000: "312.80",
        1500: "285.10",
        2000: "257.40",
        2500: "229.70",
      },
      maxPrice: {
        300: "467.80",
        500: "457.10",
        1000: "429.90",
        1500: "402.70",
        2000: "375.70",
        2500: "348.50",
      },
    },
  },
  BE: {
    KINDER: {
      Hausarzt: {
        0: "108.90",
        100: "103.10",
        200: "97.30",
        300: "91.40",
        400: "85.60",
        500: "79.80",
        600: "73.90",
      },
      Telmed: {
        0: "102.40",
        100: "96.60",
        200: "90.80",
        300: "84.90",
        400: "79.10",
        500: "78.90",
        600: "67.40",
      },
      HMO: {
        0: "101.70",
        100: "95.90",
        200: "90.10",
        300: "84.20",
        400: "78.40",
        500: "72.60",
        600: "66.70",
      },
      Standard: {
        0: "115.90",
        100: "110.60",
        200: "104.20",
        300: "98.40",
        400: "92.60",
        500: "86.70",
        600: "80.90",
      },
      maxPrice: {
        0: "180.70",
        100: "142.65",
        200: "169.00",
        300: "134.30",
        400: "157.30",
        500: "141.10",
        600: "145.70",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "301.00",
        500: "290.20",
        1000: "262.90",
        1500: "235.60",
        2000: "208.40",
        2500: "181.10",
      },
      Telmed: {
        300: "294.80",
        500: "283.90",
        1000: "256.60",
        1500: "229.30",
        2000: "202.10",
        2500: "174.80",
      },
      HMO: {
        300: "291.70",
        500: "280.80",
        1000: "253.50",
        1500: "226.20",
        2000: "199.00",
        2500: "171.70",
      },
      Standard: {
        300: "313.60",
        500: "302.70",
        1000: "275.40",
        1500: "248.10",
        2000: "220.90",
        2500: "193.60",
      },
      maxPrice: {
        300: "572.70",
        500: "561.90",
        1000: "534.80",
        1500: "507.60",
        2000: "480.50",
        2500: "453.40",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "420.00",
        500: "409.20",
        1000: "382.10",
        1500: "354.90",
        2000: "327.90",
        2500: "300.70",
      },
      Telmed: {
        300: "424.30",
        500: "413.30",
        1000: "385.60",
        1500: "357.80",
        2000: "330.20",
        2500: "302.40",
      },
      HMO: {
        300: "412.70",
        500: "401.90",
        1000: "374.70",
        1500: "347.60",
        2000: "320.50",
        2500: "293.40",
      },
      Standard: {
        300: "482.30",
        500: "471.50",
        1000: "444.20",
        1500: "416.90",
        2000: "389.70",
        2500: "362.40",
      },
      maxPrice: {
        300: "763.60",
        500: "752.80",
        1000: "725.60",
        1500: "698.50",
        2000: "671.40",
        2500: "644.30",
      },
    },
  },
  BL: {
    KINDER: {
      Hausarzt: {
        0: "102.00",
        100: "96.50",
        200: "90.70",
        300: "84.80",
        400: "79.30",
        500: "73.90",
        600: "68.90",
      },
      Telmed: {
        0: "98.90",
        100: "93.10",
        200: "87.30",
        300: "81.40",
        400: "75.60",
        500: "73.80",
        600: "63.90",
      },
      HMO: {
        0: "102.00",
        100: "96.50",
        200: "90.70",
        300: "84.80",
        400: "79.30",
        500: "73.90",
        600: "68.90",
      },
      Standard: {
        0: "109.10",
        100: "106.60",
        200: "97.40",
        300: "91.60",
        400: "85.80",
        500: "79.90",
        600: "74.10",
      },
      maxPrice: {
        0: "162.90",
        100: "134.90",
        200: "135.90",
        300: "126.70",
        400: "125.70",
        500: "124.70",
        600: "112.50",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "310.40",
        500: "299.50",
        1000: "272.20",
        1500: "244.90",
        2000: "217.70",
        2500: "190.40",
      },
      Telmed: {
        300: "301.40",
        500: "290.60",
        1000: "263.40",
        1500: "236.30",
        2000: "208.80",
        2500: "184.00",
      },
      HMO: {
        300: "300.70",
        500: "289.90",
        1000: "262.60",
        1500: "235.30",
        2000: "208.10",
        2500: "180.80",
      },
      Standard: {
        300: "323.30",
        500: "312.40",
        1000: "285.10",
        1500: "257.80",
        2000: "230.60",
        2500: "203.30",
      },
      maxPrice: {
        300: "490.05",
        500: "446.15",
        1000: "418.85",
        1500: "396.05",
        2000: "368.75",
        2500: "341.45",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "411.10",
        500: "400.30",
        1000: "373.20",
        1500: "346.00",
        2000: "318.90",
        2500: "291.80",
      },
      Telmed: {
        300: "401.70",
        500: "390.90",
        1000: "363.80",
        1500: "336.60",
        2000: "309.60",
        2500: "281.90",
      },
      HMO: {
        300: "406.50",
        500: "395.70",
        1000: "368.50",
        1500: "341.40",
        2000: "346.00",
        2500: "287.10",
      },
      Standard: {
        300: "467.10",
        500: "456.30",
        1000: "429.20",
        1500: "402.00",
        2000: "374.90",
        2500: "347.80",
      },
      maxPrice: {
        300: "585.90",
        500: "575.10",
        1000: "547.90",
        1500: "520.80",
        2000: "493.70",
        2500: "466.50",
      },
    },
  },
  BS: {
    KINDER: {
      Hausarzt: {
        0: "116.40",
        100: "111.30",
        200: "105.50",
        300: "100.10",
        400: "94.30",
        500: "88.50",
        600: "82.60",
      },
      Telmed: {
        0: "115.90",
        100: "110.10",
        200: "104.30",
        300: "98.40",
        400: "92.60",
        500: "87.30",
        600: "80.90",
      },
      HMO: {
        0: "116.40",
        100: "111.30",
        200: "105.50",
        300: "99.60",
        400: "93.80",
        500: "88.00",
        600: "82.10",
      },
      Standard: {
        0: "130.20",
        100: "124.40",
        200: "118.60",
        300: "112.70",
        400: "106.90",
        500: "103.90",
        600: "95.20",
      },
      maxPrice: {
        0: "186.60",
        100: "155.20",
        200: "174.90",
        300: "144.00",
        400: "163.30",
        500: "147.30",
        600: "151.60",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "343.00",
        500: "332.20",
        1000: "305.10",
        1500: "277.90",
        2000: "250.90",
        2500: "223.70",
      },
      Telmed: {
        300: "339.50",
        500: "328.70",
        1000: "301.60",
        1500: "274.40",
        2000: "247.30",
        2500: "220.20",
      },
      HMO: {
        300: "341.50",
        500: "330.70",
        1000: "303.50",
        1500: "276.40",
        2000: "249.30",
        2500: "222.10",
      },
      Standard: {
        300: "378.10",
        500: "367.20",
        1000: "339.90",
        1500: "312.60",
        2000: "285.40",
        2500: "258.10",
      },
      maxPrice: {
        300: "591.50",
        500: "580.70",
        1000: "553.60",
        1500: "526.40",
        2000: "499.40",
        2500: "472.20",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "457.20",
        500: "446.40",
        1000: "419.30",
        1500: "392.10",
        2000: "365.10",
        2500: "337.90",
      },
      Telmed: {
        300: "452.60",
        500: "441.80",
        1000: "414.60",
        1500: "387.50",
        2000: "360.40",
        2500: "333.33",
      },
      HMO: {
        300: "455.20",
        500: "444.40",
        1000: "417.20",
        1500: "390.10",
        2000: "363.00",
        2500: "335.90",
      },
      Standard: {
        300: "517.20",
        500: "506.40",
        1000: "479.30",
        1500: "452.10",
        2000: "425.10",
        2500: "397.90",
      },
      maxPrice: {
        300: "788.70",
        500: "777.90",
        1000: "750.70",
        1500: "723.60",
        2000: "696.50",
        2500: "669.40",
      },
    },
  },
  FR: {
    KINDER: {
      Hausarzt: {
        0: "94.00",
        100: "88.20",
        200: "82.40",
        300: "76.50",
        400: "70.70",
        500: "64.90",
        600: "59.00",
      },
      Telmed: {
        0: "93.70",
        100: "87.90",
        200: "82.10",
        300: "76.20",
        400: "70.40",
        500: "67.00",
        600: "58.70",
      },
      HMO: {
        0: "91.80",
        100: "86.00",
        200: "80.20",
        300: "74.30",
        400: "68.50",
        500: "62.70",
        600: "58.10",
      },
      Standard: {
        0: "104.10",
        100: "98.30",
        200: "92.50",
        300: "86.60",
        400: "80.80",
        500: "77.80",
        600: "69.10",
      },
      maxPrice: {
        0: "194.10",
        100: "134.60",
        200: "182.40",
        300: "122.90",
        400: "170.70",
        500: "122.90",
        600: "159.10",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "274.00",
        500: "263.20",
        1000: "235.90",
        1500: "208.60",
        2000: "181.30",
        2500: "154.00",
      },
      Telmed: {
        300: "268.30",
        500: "257.50",
        1000: "230.20",
        1500: "202.90",
        2000: "175.60",
        2500: "152.60",
      },
      HMO: {
        300: "265.50",
        500: "254.60",
        1000: "227.30",
        1500: "200.00",
        2000: "172.80",
        2500: "152.60",
      },
      Standard: {
        300: "285.40",
        500: "274.60",
        1000: "247.30",
        1500: "220.00",
        2000: "192.80",
        2500: "165.50",
      },
      maxPrice: {
        300: "561.60",
        500: "550.70",
        1000: "523.60",
        1500: "496.50",
        2000: "469.30",
        2500: "442.30",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "386.30",
        500: "375.50",
        1000: "348.30",
        1500: "321.20",
        2000: "294.10",
        2500: "267.00",
      },
      Telmed: {
        300: "373.20",
        500: "362.40",
        1000: "335.20",
        1500: "308.10",
        2000: "281.00",
        2500: "253.80",
      },
      HMO: {
        300: "364.30",
        500: "353.50",
        1000: "326.40",
        1500: "299.20",
        2000: "272.20",
        2500: "245.00",
      },
      Standard: {
        300: "430.80",
        500: "420.00",
        1000: "392.90",
        1500: "365.70",
        2000: "338.70",
        2500: "311.50",
      },
      maxPrice: {
        300: "748.70",
        500: "737.90",
        1000: "710.80",
        1500: "683.60",
        2000: "656.50",
        2500: "629.40",
      },
    },
  },
  GE: {
    KINDER: {
      Hausarzt: {
        0: "106.70",
        100: "100.90",
        200: "95.10",
        300: "89.20",
        400: "83.40",
        500: "77.60",
        600: "71.70",
      },
      Telmed: {
        0: "103.80",
        100: "98.00",
        200: "92.20",
        300: "86.30",
        400: "80.50",
        500: "74.70",
        600: "68.80",
      },
      HMO: {
        0: "106.10",
        100: "100.30",
        200: "94.50",
        300: "88.60",
        400: "82.80",
        500: "77.00",
        600: "71.10",
      },
      Standard: {
        0: "119.20",
        100: "113.40",
        200: "107.60",
        300: "101.70",
        400: "95.90",
        500: "90.10",
        600: "84.20",
      },
      maxPrice: {
        0: "212.10",
        100: "169.60",
        200: "184.90",
        300: "179.10",
        400: "173.20",
        500: "167.40",
        600: "161.60",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "348.80",
        500: "337.70",
        1000: "310.00",
        1500: "282.30",
        2000: "254.60",
        2500: "226.90",
      },
      Telmed: {
        300: "354.20",
        500: "343.40",
        1000: "316.10",
        1500: "288.80",
        2000: "261.60",
        2500: "234.30",
      },
      HMO: {
        300: "344.90",
        500: "333.80",
        1000: "306.10",
        1500: "278.40",
        2000: "250.70",
        2500: "223.00",
      },
      Standard: {
        300: "376.80",
        500: "365.90",
        1000: "338.60",
        1500: "311.30",
        2000: "284.10",
        2500: "256.80",
      },
      maxPrice: {
        300: "638.05",
        500: "606.20",
        1000: "578.60",
        1500: "550.80",
        2000: "523.10",
        2500: "495.40",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "451.10",
        500: "440.30",
        1000: "413.20",
        1500: "386.00",
        2000: "358.90",
        2500: "331.80",
      },
      Telmed: {
        300: "438.50",
        500: "427.70",
        1000: "400.50",
        1500: "373.40",
        2000: "346.30",
        2500: "319.10",
      },
      HMO: {
        300: "448.60",
        500: "437.80",
        1000: "410.60",
        1500: "383.50",
        2000: "356.40",
        2500: "329.30",
      },
      Standard: {
        300: "503.90",
        500: "493.10",
        1000: "466.00",
        1500: "438.80",
        2000: "411.80",
        2500: "384.60",
      },
      maxPrice: {
        300: "845.60",
        500: "834.50",
        1000: "806.80",
        1500: "779.10",
        2000: "751.40",
        2500: "723.70",
      },
    },
  },
  GL: {
    KINDER: {
      Hausarzt: {
        0: "74.60",
        100: "72.70",
        200: "64.10",
        300: "58.30",
        400: "52.80",
        500: "47.30",
        600: "42.50",
      },
      Telmed: {
        0: "73.40",
        100: "71.80",
        200: "63.10",
        300: "57.30",
        400: "52.00",
        500: "46.60",
        600: "42.00",
      },
      HMO: {
        0: "74.60",
        100: "72.70",
        200: "64.10",
        300: "58.30",
        400: "52.80",
        500: "47.30",
        600: "42.50",
      },
      Standard: {
        0: "70.00",
        100: "82.40",
        200: "68.60",
        300: "52.50",
        400: "46.70",
        500: "41.00",
        600: "38.00",
      },
      maxPrice: {
        0: "125.80",
        100: "109.90",
        200: "107.30",
        300: "101.50",
        400: "95.70",
        500: "96.60",
        600: "84.00",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "235.00",
        500: "224.70",
        1000: "198.80",
        1500: "172.90",
        2000: "146.90",
        2500: "138.70",
      },
      Telmed: {
        300: "231.20",
        500: "221.10",
        1000: "195.60",
        1500: "170.10",
        2000: "144.60",
        2500: "136.40",
      },
      HMO: {
        300: "235.00",
        500: "224.70",
        1000: "198.80",
        1500: "172.90",
        2000: "146.90",
        2500: "138.70",
      },
      Standard: {
        300: "251.40",
        500: "240.30",
        1000: "212.60",
        1500: "184.90",
        2000: "157.10",
        2500: "141.20",
      },
      maxPrice: {
        300: "389.60",
        500: "378.90",
        1000: "352.00",
        1500: "324.50",
        2000: "297.60",
        2500: "270.60",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "328.50",
        500: "317.70",
        1000: "292.40",
        1500: "266.20",
        2000: "241.10",
        2500: "214.90",
      },
      Telmed: {
        300: "316.80",
        500: "306.30",
        1000: "278.90",
        1500: "253.80",
        2000: "229.70",
        2500: "202.70",
      },
      HMO: {
        300: "309.60",
        500: "298.90",
        1000: "273.60",
        1500: "247.30",
        2000: "222.20",
        2500: "203.00",
      },
      Standard: {
        300: "344.40",
        500: "333.30",
        1000: "305.60",
        1500: "277.90",
        2000: "250.10",
        2500: "222.50",
      },
      maxPrice: {
        300: "464.60",
        500: "453.90",
        1000: "426.90",
        1500: "399.50",
        2000: "372.50",
        2500: "345.50",
      },
    },
  },
  GR: {
    KINDER: {
      Hausarzt: {
        0: "83.15",
        100: "78.70",
        200: "72.35",
        300: "67.00",
        400: "61.20",
        500: "56.55",
        600: "50.50",
      },
      Telmed: {
        0: "82.30",
        100: "76.50",
        200: "70.70",
        300: "64.80",
        400: "59.00",
        500: "57.60",
        600: "50.30",
      },
      HMO: {
        0: "83.15",
        100: "78.70",
        200: "72.35",
        300: "67.00",
        400: "61.20",
        500: "56.55",
        600: "50.50",
      },
      Standard: {
        0: "87.50",
        100: "83.15",
        200: "76.15",
        300: "70.90",
        400: "64.75",
        500: "59.50",
        600: "53.40",
      },
      maxPrice: {
        0: "143.70",
        100: "119.10",
        200: "114.40",
        300: "108.50",
        400: "102.70",
        500: "114.50",
        600: "91.00",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "262.60",
        500: "252.90",
        1000: "226.20",
        1500: "198.90",
        2000: "171.70",
        2500: "147.20",
      },
      Telmed: {
        300: "243.50",
        500: "240.70",
        1000: "205.50",
        1500: "178.40",
        2000: "151.30",
        2500: "147.20",
      },
      HMO: {
        300: "253.70",
        500: "243.90",
        1000: "217.90",
        1500: "190.60",
        2000: "163.40",
        2500: "147.20",
      },
      Standard: {
        300: "275.30",
        500: "264.50",
        1000: "237.20",
        1500: "209.90",
        2000: "182.70",
        2500: "155.40",
      },
      maxPrice: {
        300: "406.50",
        500: "395.35",
        1000: "367.30",
        1500: "339.30",
        2000: "311.35",
        2500: "283.30",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "350.10",
        500: "340.40",
        1000: "315.90",
        1500: "291.30",
        2000: "266.80",
        2500: "245.70",
      },
      Telmed: {
        300: "347.80",
        500: "339.60",
        1000: "309.90",
        1500: "282.70",
        2000: "255.70",
        2500: "228.50",
      },
      HMO: {
        300: "338.30",
        500: "328.50",
        1000: "304.00",
        1500: "279.40",
        2000: "255.00",
        2500: "224.90",
      },
      Standard: {
        300: "365.05",
        500: "354.10",
        1000: "324.90",
        1500: "299.35",
        2000: "271.05",
        2500: "240.95",
      },
      maxPrice: {
        300: "487.40",
        500: "476.30",
        1000: "448.60",
        1500: "420.90",
        2000: "393.20",
        2500: "365.50",
      },
    },
  },
  JU: {
    KINDER: {
      Hausarzt: {
        0: "97.80",
        100: "92.00",
        200: "86.20",
        300: "80.30",
        400: "74.50",
        500: "68.70",
        600: "62.80",
      },
      Telmed: {
        0: "94.30",
        100: "91.20",
        200: "83.90",
        300: "78.80",
        400: "73.60",
        500: "67.90",
        600: "62.00",
      },
      HMO: {
        0: "92.80",
        100: "87.00",
        200: "81.20",
        300: "75.30",
        400: "69.50",
        500: "63.70",
        600: "59.10",
      },
      Standard: {
        0: "106.50",
        100: "102.00",
        200: "94.80",
        300: "89.00",
        400: "83.20",
        500: "77.30",
        600: "71.50",
      },
      maxPrice: {
        0: "198.80",
        100: "141.70",
        200: "187.10",
        300: "181.30",
        400: "175.40",
        500: "169.60",
        600: "163.80",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "313.60",
        500: "302.80",
        1000: "275.50",
        1500: "248.20",
        2000: "221.00",
        2500: "193.70",
      },
      Telmed: {
        300: "297.20",
        500: "287.40",
        1000: "262.90",
        1500: "238.40",
        2000: "213.80",
        2500: "187.10",
      },
      HMO: {
        300: "303.80",
        500: "293.00",
        1000: "265.70",
        1500: "238.40",
        2000: "211.20",
        2500: "183.90",
      },
      Standard: {
        300: "326.60",
        500: "315.80",
        1000: "288.50",
        1500: "261.20",
        2000: "234.00",
        2500: "206.70",
      },
      maxPrice: {
        300: "588.00",
        500: "577.20",
        1000: "550.00",
        1500: "522.90",
        2000: "495.80",
        2500: "468.70",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "418.80",
        500: "408.00",
        1000: "380.90",
        1500: "353.70",
        2000: "326.70",
        2500: "299.50",
      },
      Telmed: {
        300: "407.20",
        500: "397.40",
        1000: "372.20",
        1500: "345.00",
        2000: "318.00",
        2500: "290.80",
      },
      HMO: {
        300: "397.60",
        500: "386.80",
        1000: "359.70",
        1500: "332.50",
        2000: "305.50",
        2500: "278.30",
      },
      Standard: {
        300: "455.70",
        500: "444.90",
        1000: "417.80",
        1500: "390.60",
        2000: "363.50",
        2500: "336.40",
      },
      maxPrice: {
        300: "735.30",
        500: "724.40",
        1000: "697.30",
        1500: "670.20",
        2000: "643.10",
        2500: "615.90",
      },
    },
  },
  LU: {
    KINDER: {
      Hausarzt: {
        0: "85.10",
        100: "79.30",
        200: "73.50",
        300: "67.60",
        400: "61.80",
        500: "56.00",
        600: "50.90",
      },
      Telmed: {
        0: "85.20",
        100: "80.70",
        200: "74.40",
        300: "69.00",
        400: "63.20",
        500: "58.30",
        600: "51.50",
      },
      HMO: {
        0: "83.20",
        100: "77.40",
        200: "71.60",
        300: "65.70",
        400: "59.90",
        500: "54.10",
        600: "50.90",
      },
      Standard: {
        0: "92.60",
        100: "88.70",
        200: "80.90",
        300: "75.10",
        400: "69.30",
        500: "63.40",
        600: "57.60",
      },
      maxPrice: {
        0: "137.10",
        100: "114.00",
        200: "111.40",
        300: "102.30",
        400: "99.70",
        500: "99.10",
        600: "88.00",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "258.10",
        500: "247.30",
        1000: "220.00",
        1500: "192.70",
        2000: "165.50",
        2500: "143.80",
      },
      Telmed: {
        300: "240.30",
        500: "229.30",
        1000: "201.50",
        1500: "173.80",
        2000: "150.50",
        2500: "143.80",
      },
      HMO: {
        300: "250.10",
        500: "239.20",
        1000: "211.90",
        1500: "184.60",
        2000: "157.40",
        2500: "143.80",
      },
      Standard: {
        300: "268.90",
        500: "258.00",
        1000: "230.70",
        1500: "203.40",
        2000: "176.20",
        2500: "148.90",
      },
      maxPrice: {
        300: "412.45",
        500: "374.10",
        1000: "346.30",
        1500: "318.95",
        2000: "292.40",
        2500: "269.60",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "344.00",
        500: "333.20",
        1000: "306.00",
        1500: "271.20",
        2000: "251.80",
        2500: "224.60",
      },
      Telmed: {
        300: "337.50",
        500: "326.80",
        1000: "299.60",
        1500: "272.40",
        2000: "245.40",
        2500: "218.20",
      },
      HMO: {
        300: "336.30",
        500: "325.50",
        1000: "298.40",
        1500: "271.20",
        2000: "244.20",
        2500: "217.00",
      },
      Standard: {
        300: "382.10",
        500: "371.30",
        1000: "344.10",
        1500: "317.00",
        2000: "289.90",
        2500: "262.80",
      },
      maxPrice: {
        300: "499.40",
        500: "493.10",
        1000: "461.20",
        1500: "434.00",
        2000: "406.70",
        2500: "379.40",
      },
    },
  },
  NE: {
    KINDER: {
      Hausarzt: {
        0: "102.80",
        100: "97.00",
        200: "91.20",
        300: "85.30",
        400: "79.50",
        500: "73.70",
        600: "67.80",
      },
      Telmed: {
        0: "98.90",
        100: "96.40",
        200: "89.40",
        300: "84.70",
        400: "78.90",
        500: "75.30",
        600: "67.20",
      },
      HMO: {
        0: "101.10",
        100: "95.30",
        200: "89.50",
        300: "83.60",
        400: "77.80",
        500: "72.00",
        600: "66.10",
      },
      Standard: {
        0: "114.80",
        100: "109.00",
        200: "103.20",
        300: "97.30",
        400: "91.50",
        500: "87.70",
        600: "79.80",
      },
      maxPrice: {
        0: "163.30",
        100: "154.00",
        200: "150.40",
        300: "142.10",
        400: "138.70",
        500: "134.10",
        600: "127.00",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "317.50",
        500: "308.40",
        1000: "285.80",
        1500: "270.70",
        2000: "243.50",
        2500: "216.20",
      },
      Telmed: {
        300: "311.70",
        500: "302.80",
        1000: "280.50",
        1500: "258.20",
        2000: "235.90",
        2500: "209.20",
      },
      HMO: {
        300: "317.50",
        500: "308.40",
        1000: "285.80",
        1500: "260.20",
        2000: "233.00",
        2500: "205.70",
      },
      Standard: {
        300: "350.10",
        500: "339.30",
        1000: "312.00",
        1500: "284.70",
        2000: "257.50",
        2500: "230.20",
      },
      maxPrice: {
        300: "511.50",
        500: "500.50",
        1000: "472.70",
        1500: "445.00",
        2000: "403.60",
        2500: "389.60",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "425.00",
        500: "414.20",
        1000: "387.00",
        1500: "359.90",
        2000: "332.80",
        2500: "305.60",
      },
      Telmed: {
        300: "427.00",
        500: "418.10",
        1000: "392.10",
        1500: "365.00",
        2000: "337.90",
        2500: "310.80",
      },
      HMO: {
        300: "409.10",
        500: "398.30",
        1000: "373.60",
        1500: "347.80",
        2000: "323.20",
        2500: "297.30",
      },
      Standard: {
        300: "482.80",
        500: "472.00",
        1000: "444.90",
        1500: "417.70",
        2000: "390.60",
        2500: "363.50",
      },
      maxPrice: {
        300: "654.70",
        500: "643.90",
        1000: "616.70",
        1500: "589.60",
        2000: "562.50",
        2500: "535.40",
      },
    },
  },
  NW: {
    KINDER: {
      Hausarzt: {
        0: "74.90",
        100: "69.00",
        200: "63.20",
        300: "57.40",
        400: "52.70",
        500: "47.30",
        600: "43.70",
      },
      Telmed: {
        0: "74.00",
        100: "68.20",
        200: "62.40",
        300: "57.30",
        400: "52.70",
        500: "47.30",
        600: "43.00",
      },
      HMO: {
        0: "74.90",
        100: "69.00",
        200: "63.20",
        300: "57.40",
        400: "52.70",
        500: "47.30",
        600: "43.70",
      },
      Standard: {
        0: "78.05",
        100: "78.00",
        200: "70.30",
        300: "64.20",
        400: "58.40",
        500: "48.90",
        600: "46.70",
      },
      maxPrice: {
        0: "127.90",
        100: "122.10",
        200: "116.30",
        300: "110.40",
        400: "104.60",
        500: "98.80",
        600: "92.90",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "220.30",
        500: "209.50",
        1000: "182.20",
        1500: "154.90",
        2000: "128.60",
        2500: "122.70",
      },
      Telmed: {
        300: "215.80",
        500: "204.90",
        1000: "177.60",
        1500: "150.30",
        2000: "124.00",
        2500: "122.70",
      },
      HMO: {
        300: "213.50",
        500: "202.70",
        1000: "175.40",
        1500: "148.10",
        2000: "122.70",
        2500: "122.70",
      },
      Standard: {
        300: "229.50",
        500: "218.70",
        1000: "191.40",
        1500: "164.00",
        2000: "137.80",
        2500: "122.80",
      },
      maxPrice: {
        300: "357.50",
        500: "346.50",
        1000: "318.80",
        1500: "291.00",
        2000: "263.40",
        2500: "235.60",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "287.00",
        500: "276.20",
        1000: "249.00",
        1500: "221.90",
        2000: "194.80",
        2500: "184.60",
      },
      Telmed: {
        300: "287.00",
        500: "276.20",
        1000: "249.00",
        1500: "221.90",
        2000: "194.80",
        2500: "181.50",
      },
      HMO: {
        300: "280.20",
        500: "269.40",
        1000: "242.20",
        1500: "215.10",
        2000: "188.00",
        2500: "181.50",
      },
      Standard: {
        300: "337.00",
        500: "325.95",
        1000: "298.20",
        1500: "270.50",
        2000: "242.80",
        2500: "215.10",
      },
      maxPrice: {
        300: "451.10",
        500: "440.30",
        1000: "413.10",
        1500: "386.00",
        2000: "358.90",
        2500: "331.70",
      },
    },
  },
  OW: {
    KINDER: {
      Hausarzt: {
        0: "71.40",
        100: "71.90",
        200: "61.40",
        300: "55.10",
        400: "49.60",
        500: "44.10",
        600: "40.70",
      },
      Telmed: {
        0: "70.30",
        100: "71.90",
        200: "60.40",
        300: "54.20",
        400: "48.90",
        500: "43.40",
        600: "40.20",
      },
      HMO: {
        0: "71.40",
        100: "71.90",
        200: "61.40",
        300: "55.10",
        400: "49.60",
        500: "44.10",
        600: "40.70",
      },
      Standard: {
        0: "76.40",
        100: "79.60",
        200: "65.70",
        300: "58.90",
        400: "53.10",
        500: "47.20",
        600: "43.50",
      },
      maxPrice: {
        0: "111.30",
        100: "104.20",
        200: "99.60",
        300: "92.60",
        400: "91.60",
        500: "90.60",
        600: "76.30",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "225.20",
        500: "214.80",
        1000: "189.00",
        1500: "161.50",
        2000: "144.40",
        2500: "132.00",
      },
      Telmed: {
        300: "221.50",
        500: "211.40",
        1000: "185.90",
        1500: "160.40",
        2000: "134.90",
        2500: "130.70",
      },
      HMO: {
        300: "225.20",
        500: "214.80",
        1000: "189.00",
        1500: "161.50",
        2000: "137.00",
        2500: "132.00",
      },
      Standard: {
        300: "240.80",
        500: "229.70",
        1000: "202.10",
        1500: "174.30",
        2000: "146.60",
        2500: "132.20",
      },
      maxPrice: {
        300: "337.60",
        500: "326.90",
        1000: "299.70",
        1500: "272.50",
        2000: "245.50",
        2500: "218.30",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "292.50",
        500: "281.70",
        1000: "254.60",
        1500: "227.40",
        2000: "200.40",
        2500: "189.50",
      },
      Telmed: {
        300: "299.60",
        500: "288.80",
        1000: "261.70",
        1500: "234.50",
        2000: "207.40",
        2500: "189.50",
      },
      HMO: {
        300: "292.50",
        500: "281.70",
        1000: "254.60",
        1500: "227.40",
        2000: "200.40",
        2500: "186.00",
      },
      Standard: {
        300: "329.80",
        500: "318.70",
        1000: "291.10",
        1500: "263.30",
        2000: "235.60",
        2500: "208.00",
      },
      maxPrice: {
        300: "450.20",
        500: "439.40",
        1000: "412.20",
        1500: "385.10",
        2000: "358.00",
        2500: "330.90",
      },
    },
  },
  SG: {
    KINDER: {
      Hausarzt: {
        0: "83.10",
        100: "77.30",
        200: "71.50",
        300: "65.60",
        400: "59.80",
        500: "54.00",
        600: "50.20",
      },
      Telmed: {
        0: "84.60",
        100: "81.50",
        200: "73.90",
        300: "68.50",
        400: "63.20",
        500: "57.80",
        600: "52.40",
      },
      HMO: {
        0: "78.40",
        100: "72.60",
        200: "66.80",
        300: "60.90",
        400: "55.10",
        500: "50.20",
        600: "50.20",
      },
      Standard: {
        0: "92.00",
        100: "87.50",
        200: "80.30",
        300: "74.50",
        400: "68.70",
        500: "62.80",
        600: "57.00",
      },
      maxPrice: {
        0: "144.30",
        100: "119.00",
        200: "132.60",
        300: "107.30",
        400: "120.90",
        500: "102.80",
        600: "109.30",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "260.10",
        500: "249.20",
        1000: "221.90",
        1500: "194.60",
        2000: "167.40",
        2500: "144.80",
      },
      Telmed: {
        300: "252.30",
        500: "241.30",
        1000: "213.50",
        1500: "185.80",
        2000: "158.10",
        2500: "144.80",
      },
      HMO: {
        300: "251.90",
        500: "241.10",
        1000: "213.80",
        1500: "186.50",
        2000: "159.30",
        2500: "144.80",
      },
      Standard: {
        300: "270.80",
        500: "260.00",
        1000: "232.70",
        1500: "205.40",
        2000: "178.20",
        2500: "150.90",
      },
      maxPrice: {
        300: "437.40",
        500: "426.50",
        1000: "399.50",
        1500: "372.30",
        2000: "345.20",
        2500: "318.10",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "367.60",
        500: "356.80",
        1000: "319.20",
        1500: "291.40",
        2000: "263.80",
        2500: "236.00",
      },
      Telmed: {
        300: "336.30",
        500: "325.20",
        1000: "297.50",
        1500: "269.80",
        2000: "242.10",
        2500: "214.40",
      },
      HMO: {
        300: "346.90",
        500: "336.20",
        1000: "309.00",
        1500: "281.80",
        2000: "254.20",
        2500: "226.40",
      },
      Standard: {
        300: "397.20",
        500: "386.10",
        1000: "358.40",
        1500: "330.70",
        2000: "303.00",
        2500: "275.30",
      },
      maxPrice: {
        300: "583.20",
        500: "572.40",
        1000: "545.20",
        1500: "518.10",
        2000: "491.00",
        2500: "463.80",
      },
    },
  },
  SH: {
    KINDER: {
      Hausarzt: {
        0: "83.50",
        100: "77.70",
        200: "71.90",
        300: "66.00",
        400: "60.20",
        500: "54.40",
        600: "49.10",
      },
      Telmed: {
        0: "86.70",
        100: "80.90",
        200: "75.10",
        300: "69.20",
        400: "63.40",
        500: "61.60",
        600: "53.00",
      },
      HMO: {
        0: "83.50",
        100: "77.70",
        200: "71.90",
        300: "66.00",
        400: "60.20",
        500: "54.40",
        600: "49.10",
      },
      Standard: {
        0: "91.20",
        100: "85.40",
        200: "79.60",
        300: "73.70",
        400: "67.90",
        500: "62.10",
        600: "56.20",
      },
      maxPrice: {
        0: "142.30",
        100: "127.20",
        200: "126.80",
        300: "121.00",
        400: "115.10",
        500: "113.10",
        600: "103.50",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "262.50",
        500: "259.60",
        1000: "224.50",
        1500: "197.40",
        2000: "170.30",
        2500: "156.80",
      },
      Telmed: {
        300: "256.70",
        500: "253.70",
        1000: "218.70",
        1500: "191.60",
        2000: "164.50",
        2500: "156.80",
      },
      HMO: {
        300: "262.50",
        500: "259.60",
        1000: "224.50",
        1500: "197.40",
        2000: "170.30",
        2500: "156.80",
      },
      Standard: {
        300: "291.60",
        500: "288.70",
        1000: "253.70",
        1500: "226.50",
        2000: "199.50",
        2500: "172.30",
      },
      maxPrice: {
        300: "423.20",
        500: "412.10",
        1000: "384.30",
        1500: "356.70",
        2000: "328.90",
        2500: "301.20",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "348.10",
        500: "337.40",
        1000: "310.20",
        1500: "283.00",
        2000: "256.00",
        2500: "228.80",
      },
      Telmed: {
        300: "356.50",
        500: "345.70",
        1000: "318.60",
        1500: "291.40",
        2000: "264.40",
        2500: "237.20",
      },
      HMO: {
        300: "348.10",
        500: "337.40",
        1000: "310.20",
        1500: "283.00",
        2000: "256.00",
        2500: "228.80",
      },
      Standard: {
        300: "399.80",
        500: "389.00",
        1000: "361.80",
        1500: "334.70",
        2000: "307.60",
        2500: "280.40",
      },
      maxPrice: {
        300: "532.70",
        500: "521.90",
        1000: "494.80",
        1500: "467.60",
        2000: "440.50",
        2500: "413.40",
      },
    },
  },
  SO: {
    KINDER: {
      Hausarzt: {
        0: "84.50",
        100: "81.20",
        200: "75.40",
        300: "69.50",
        400: "63.70",
        500: "57.90",
        600: "51.80",
      },
      Telmed: {
        0: "83.20",
        100: "83.70",
        200: "72.40",
        300: "67.10",
        400: "61.70",
        500: "56.30",
        600: "51.00",
      },
      HMO: {
        0: "84.50",
        100: "78.80",
        200: "73.00",
        300: "67.10",
        400: "61.30",
        500: "55.50",
        600: "51.80",
      },
      Standard: {
        0: "90.40",
        100: "92.50",
        200: "78.70",
        300: "72.90",
        400: "67.10",
        500: "61.20",
        600: "55.40",
      },
      maxPrice: {
        0: "143.10",
        100: "128.30",
        200: "122.50",
        300: "116.60",
        400: "110.80",
        500: "105.80",
        600: "91.30",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "275.90",
        500: "265.10",
        1000: "237.90",
        1500: "210.80",
        2000: "183.70",
        2500: "158.90",
      },
      Telmed: {
        300: "262.20",
        500: "251.90",
        1000: "226.60",
        1500: "201.00",
        2000: "175.50",
        2500: "154.70",
      },
      HMO: {
        300: "266.50",
        500: "256.10",
        1000: "230.10",
        1500: "203.00",
        2000: "175.90",
        2500: "155.30",
      },
      Standard: {
        300: "285.00",
        500: "273.90",
        1000: "246.20",
        1500: "218.50",
        2000: "190.80",
        2500: "168.20",
      },
      maxPrice: {
        300: "449.50",
        500: "438.80",
        1000: "411.90",
        1500: "384.40",
        2000: "357.40",
        2500: "330.50",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "367.90",
        500: "357.10",
        1000: "329.90",
        1500: "302.80",
        2000: "275.70",
        2500: "248.50",
      },
      Telmed: {
        300: "359.10",
        500: "348.90",
        1000: "322.30",
        1500: "294.50",
        2000: "266.90",
        2500: "239.20",
      },
      HMO: {
        300: "357.40",
        500: "346.70",
        1000: "319.50",
        1500: "292.30",
        2000: "265.30",
        2500: "238.10",
      },
      Standard: {
        300: "390.40",
        500: "379.20",
        1000: "351.60",
        1500: "323.90",
        2000: "296.10",
        2500: "268.50",
      },
      maxPrice: {
        300: "530.50",
        500: "519.80",
        1000: "492.90",
        1500: "465.40",
        2000: "438.50",
        2500: "411.50",
      },
    },
  },
  SZ: {
    KINDER: {
      Hausarzt: {
        0: "79.20",
        100: "73.40",
        200: "67.60",
        300: "61.70",
        400: "55.90",
        500: "51.90",
        600: "46.50",
      },
      Telmed: {
        0: "77.40",
        100: "71.60",
        200: "65.80",
        300: "59.90",
        400: "54.10",
        500: "52.00",
        600: "47.30",
      },
      HMO: {
        0: "77.30",
        100: "71.50",
        200: "65.70",
        300: "59.80",
        400: "54.00",
        500: "51.90",
        600: "46.50",
      },
      Standard: {
        0: "84.70",
        100: "82.20",
        200: "73.00",
        300: "67.20",
        400: "61.40",
        500: "55.50",
        600: "49.70",
      },
      maxPrice: {
        0: "147.30",
        100: "141.50",
        200: "135.70",
        300: "129.80",
        400: "124.00",
        500: "118.20",
        600: "112.30",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "247.00",
        500: "236.10",
        1000: "208.80",
        1500: "181.50",
        2000: "154.30",
        2500: "137.60",
      },
      Telmed: {
        300: "241.80",
        500: "231.00",
        1000: "203.70",
        1500: "176.40",
        2000: "149.20",
        2500: "137.60",
      },
      HMO: {
        300: "234.40",
        500: "223.60",
        1000: "196.40",
        1500: "169.30",
        2000: "146.70",
        2500: "137.60",
      },
      Standard: {
        300: "257.30",
        500: "246.40",
        1000: "219.10",
        1500: "191.80",
        2000: "164.60",
        2500: "137.70",
      },
      maxPrice: {
        300: "392.80",
        500: "382.10",
        1000: "355.10",
        1500: "327.70",
        2000: "300.70",
        2500: "273.70",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "334.80",
        500: "324.00",
        1000: "296.90",
        1500: "269.70",
        2000: "242.60",
        2500: "217.40",
      },
      Telmed: {
        300: "323.50",
        500: "312.60",
        1000: "285.60",
        1500: "258.40",
        2000: "231.20",
        2500: "206.80",
      },
      HMO: {
        300: "312.50",
        500: "301.70",
        1000: "274.50",
        1500: "247.40",
        2000: "220.30",
        2500: "198.50",
      },
      Standard: {
        300: "345.00",
        500: "334.00",
        1000: "306.60",
        1500: "279.20",
        2000: "251.80",
        2500: "224.40",
      },
      maxPrice: {
        300: "469.40",
        500: "458.60",
        1000: "431.30",
        1500: "404.00",
        2000: "376.80",
        2500: "349.50",
      },
    },
  },
  TG: {
    KINDER: {
      Hausarzt: {
        0: "85.20",
        100: "79.40",
        200: "73.60",
        300: "67.70",
        400: "61.90",
        500: "56.10",
        600: "46.40",
      },
      Telmed: {
        0: "77.80",
        100: "74.50",
        200: "67.10",
        300: "61.70",
        400: "56.40",
        500: "51.00",
        600: "45.60",
      },
      HMO: {
        0: "79.10",
        100: "73.70",
        200: "67.90",
        300: "62.00",
        400: "56.20",
        500: "50.90",
        600: "46.40",
      },
      Standard: {
        0: "84.60",
        100: "85.50",
        200: "72.90",
        300: "67.10",
        400: "61.30",
        500: "55.40",
        600: "49.60",
      },
      maxPrice: {
        0: "133.30",
        100: "113.30",
        200: "121.70",
        300: "115.80",
        400: "110.00",
        500: "104.20",
        600: "98.30",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "244.60",
        500: "233.70",
        1000: "206.40",
        1500: "179.10",
        2000: "151.90",
        2500: "136.20",
      },
      Telmed: {
        300: "239.40",
        500: "228.60",
        1000: "201.30",
        1500: "174.00",
        2000: "146.80",
        2500: "136.20",
      },
      HMO: {
        300: "236.90",
        500: "226.00",
        1000: "198.70",
        1500: "171.40",
        2000: "144.20",
        2500: "136.20",
      },
      Standard: {
        300: "254.60",
        500: "243.80",
        1000: "216.50",
        1500: "189.20",
        2000: "162.00",
        2500: "136.30",
      },
      maxPrice: {
        300: "425.80",
        500: "415.10",
        1000: "388.10",
        1500: "360.70",
        2000: "333.70",
        2500: "306.80",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "344.20",
        500: "333.50",
        1000: "306.30",
        1500: "279.10",
        2000: "252.10",
        2500: "224.90",
      },
      Telmed: {
        300: "335.90",
        500: "325.20",
        1000: "298.00",
        1500: "270.90",
        2000: "243.80",
        2500: "216.60",
      },
      HMO: {
        300: "321.40",
        500: "310.60",
        1000: "283.40",
        1500: "256.30",
        2000: "229.20",
        2500: "205.70",
      },
      Standard: {
        300: "365.20",
        500: "354.10",
        1000: "326.40",
        1500: "298.70",
        2000: "270.90",
        2500: "243.30",
      },
      maxPrice: {
        300: "541.30",
        500: "530.50",
        1000: "503.40",
        1500: "476.20",
        2000: "449.10",
        2500: "422.00",
      },
    },
  },
  TI: {
    KINDER: {
      Hausarzt: {
        0: "100.10",
        100: "94.30",
        200: "88.50",
        300: "82.60",
        400: "76.80",
        500: "71.00",
        600: "65.10",
      },
      Telmed: {
        0: "100.40",
        100: "94.60",
        200: "88.80",
        300: "82.90",
        400: "77.10",
        500: "71.30",
        600: "65.40",
      },
      HMO: {
        0: "91.40",
        100: "85.60",
        200: "79.80",
        300: "73.90",
        400: "68.10",
        500: "62.30",
        600: "58.50",
      },
      Standard: {
        0: "108.70",
        100: "102.90",
        200: "97.10",
        300: "91.20",
        400: "85.40",
        500: "79.60",
        600: "73.70",
      },
      maxPrice: {
        0: "172.70",
        100: "141.60",
        200: "161.00",
        300: "131.00",
        400: "149.30",
        500: "121.20",
        600: "137.70",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "287.00",
        500: "276.20",
        1000: "249.00",
        1500: "221.90",
        2000: "194.80",
        2500: "190.30",
      },
      Telmed: {
        300: "293.80",
        500: "283.00",
        1000: "255.90",
        1500: "228.70",
        2000: "201.70",
        2500: "183.90",
      },
      HMO: {
        300: "300.70",
        500: "289.80",
        1000: "262.50",
        1500: "235.20",
        2000: "208.00",
        2500: "180.70",
      },
      Standard: {
        300: "323.20",
        500: "312.30",
        1000: "285.00",
        1500: "257.70",
        2000: "230.50",
        2500: "203.20",
      },
      maxPrice: {
        300: "573.40",
        500: "562.60",
        1000: "535.50",
        1500: "508.30",
        2000: "481.20",
        2500: "454.10",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "432.70",
        500: "421.70",
        1000: "393.90",
        1500: "366.20",
        2000: "338.50",
        2500: "310.80",
      },
      Telmed: {
        300: "405.90",
        500: "394.90",
        1000: "367.10",
        1500: "339.40",
        2000: "311.70",
        2500: "284.00",
      },
      HMO: {
        300: "404.10",
        500: "393.30",
        1000: "366.20",
        1500: "339.00",
        2000: "312.00",
        2500: "284.80",
      },
      Standard: {
        300: "471.50",
        500: "460.60",
        1000: "433.20",
        1500: "405.40",
        2000: "378.40",
        2500: "351.00",
      },
      maxPrice: {
        300: "764.50",
        500: "753.70",
        1000: "726.60",
        1500: "699.40",
        2000: "672.30",
        2500: "645.20",
      },
    },
  },
  UR: {
    KINDER: {
      Hausarzt: {
        0: "73.20",
        100: "68.50",
        200: "63.30",
        300: "56.40",
        400: "51.00",
        500: "45.40",
        600: "41.40",
      },
      Telmed: {
        0: "68.90",
        100: "67.70",
        200: "59.60",
        300: "55.50",
        400: "50.10",
        500: "44.70",
        600: "40.90",
      },
      HMO: {
        0: "71.50",
        100: "68.40",
        200: "61.80",
        300: "56.40",
        400: "50.90",
        500: "45.40",
        600: "41.40",
      },
      Standard: {
        0: "77.80",
        100: "74.90",
        200: "66.90",
        300: "60.30",
        400: "54.50",
        500: "48.60",
        600: "44.30",
      },
      maxPrice: {
        0: "119.70",
        100: "113.90",
        200: "108.10",
        300: "102.20",
        400: "96.40",
        500: "90.60",
        600: "80.10",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "235.40",
        500: "224.60",
        1000: "197.50",
        1500: "170.30",
        2000: "143.20",
        2500: "135.40",
      },
      Telmed: {
        300: "225.70",
        500: "215.60",
        1000: "190.10",
        1500: "164.50",
        2000: "139.10",
        2500: "133.20",
      },
      HMO: {
        300: "223.40",
        500: "212.40",
        1000: "184.60",
        1500: "156.90",
        2000: "140.60",
        2500: "135.40",
      },
      Standard: {
        300: "245.40",
        500: "234.30",
        1000: "206.60",
        1500: "178.90",
        2000: "151.10",
        2500: "135.50",
      },
      maxPrice: {
        300: "358.10",
        500: "347.20",
        1000: "319.90",
        1500: "292.60",
        2000: "265.40",
        2500: "238.10",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "296.40",
        500: "285.70",
        1000: "258.50",
        1500: "231.30",
        2000: "204.30",
        2500: "186.30",
      },
      Telmed: {
        300: "301.20",
        500: "290.40",
        1000: "262.70",
        1500: "234.90",
        2000: "209.00",
        2500: "180.00",
      },
      HMO: {
        300: "297.80",
        500: "286.80",
        1000: "259.00",
        1500: "231.30",
        2000: "203.60",
        2500: "181.90",
      },
      Standard: {
        300: "336.10",
        500: "325.00",
        1000: "297.40",
        1500: "269.60",
        2000: "241.90",
        2500: "214.20",
      },
      maxPrice: {
        300: "447.50",
        500: "436.70",
        1000: "409.40",
        1500: "382.10",
        2000: "354.90",
        2500: "327.60",
      },
    },
  },
  VD: {
    KINDER: {
      Hausarzt: {
        0: "110.50",
        100: "104.70",
        200: "98.90",
        300: "93.00",
        400: "87.20",
        500: "81.40",
        600: "75.50",
      },
      Telmed: {
        0: "108.00",
        100: "102.20",
        200: "96.40",
        300: "90.50",
        400: "84.70",
        500: "78.90",
        600: "73.00",
      },
      HMO: {
        0: "108.70",
        100: "104.10",
        200: "98.30",
        300: "92.40",
        400: "86.60",
        500: "80.80",
        600: "74.90",
      },
      Standard: {
        0: "122.50",
        100: "116.70",
        200: "110.90",
        300: "105.00",
        400: "99.20",
        500: "93.40",
        600: "87.50",
      },
      maxPrice: {
        0: "193.40",
        100: "146.30",
        200: "181.80",
        300: "175.90",
        400: "170.10",
        500: "164.30",
        600: "158.40",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "313.40",
        500: "302.50",
        1000: "275.20",
        1500: "247.90",
        2000: "220.70",
        2500: "193.40",
      },
      Telmed: {
        300: "294.80",
        500: "283.80",
        1000: "256.10",
        1500: "228.30",
        2000: "200.70",
        2500: "172.90",
      },
      HMO: {
        300: "291.50",
        500: "280.50",
        1000: "252.70",
        1500: "225.00",
        2000: "197.40",
        2500: "172.40",
      },
      Standard: {
        300: "327.50",
        500: "316.50",
        1000: "288.80",
        1500: "261.00",
        2000: "233.40",
        2500: "205.60",
      },
      maxPrice: {
        300: "577.70",
        500: "566.80",
        1000: "539.70",
        1500: "512.60",
        2000: "485.50",
        2500: "458.30",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "420.70",
        500: "409.90",
        1000: "382.70",
        1500: "355.60",
        2000: "328.50",
        2500: "301.40",
      },
      Telmed: {
        300: "414.70",
        500: "403.90",
        1000: "376.80",
        1500: "349.60",
        2000: "322.60",
        2500: "295.40",
      },
      HMO: {
        300: "421.90",
        500: "411.10",
        1000: "384.00",
        1500: "356.80",
        2000: "329.70",
        2500: "302.60",
      },
      Standard: {
        300: "476.70",
        500: "465.90",
        1000: "438.70",
        1500: "411.60",
        2000: "384.50",
        2500: "357.40",
      },
      maxPrice: {
        300: "721.80",
        500: "711.00",
        1000: "683.80",
        1500: "656.70",
        2000: "629.60",
        2500: "602.50",
      },
    },
  },
  VS: {
    KINDER: {
      Hausarzt: {
        0: "87.00",
        100: "81.20",
        200: "75.40",
        300: "69.50",
        400: "63.70",
        500: "57.90",
        600: "53.80",
      },
      Telmed: {
        0: "85.40",
        100: "79.60",
        200: "73.80",
        300: "67.90",
        400: "62.10",
        500: "58.30",
        600: "51.60",
      },
      HMO: {
        0: "82.50",
        100: "79.20",
        200: "73.40",
        300: "67.50",
        400: "61.70",
        500: "55.90",
        600: "53.80",
      },
      Standard: {
        0: "95.90",
        100: "90.10",
        200: "84.30",
        300: "78.40",
        400: "72.60",
        500: "69.50",
        600: "60.90",
      },
      maxPrice: {
        0: "136.10",
        100: "118.50",
        200: "112.70",
        300: "106.80",
        400: "101.00",
        500: "107.00",
        600: "89.30",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "265.50",
        500: "254.60",
        1000: "227.30",
        1500: "200.00",
        2000: "172.80",
        2500: "147.90",
      },
      Telmed: {
        300: "255.20",
        500: "244.20",
        1000: "216.50",
        1500: "188.70",
        2000: "161.10",
        2500: "147.90",
      },
      HMO: {
        300: "257.30",
        500: "246.40",
        1000: "219.10",
        1500: "191.80",
        2000: "164.60",
        2500: "147.90",
      },
      Standard: {
        300: "276.50",
        500: "265.70",
        1000: "238.40",
        1500: "211.10",
        2000: "183.90",
        2500: "156.60",
      },
      maxPrice: {
        300: "406.15",
        500: "389.70",
        1000: "362.70",
        1500: "335.30",
        2000: "308.30",
        2500: "281.40",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "351.80",
        500: "341.00",
        1000: "313.80",
        1500: "286.70",
        2000: "259.60",
        2500: "232.50",
      },
      Telmed: {
        300: "340.20",
        500: "329.20",
        1000: "301.50",
        1500: "273.70",
        2000: "246.10",
        2500: "218.40",
      },
      HMO: {
        300: "343.70",
        500: "332.90",
        1000: "305.70",
        1500: "278.60",
        2000: "251.50",
        2500: "224.40",
      },
      Standard: {
        300: "400.20",
        500: "389.20",
        1000: "361.40",
        1500: "333.70",
        2000: "306.00",
        2500: "278.30",
      },
      maxPrice: {
        300: "504.00",
        500: "497.70",
        1000: "465.80",
        1500: "438.50",
        2000: "411.30",
        2500: "384.00",
      },
    },
  },
  ZH: {
    KINDER: {
      Hausarzt: {
        0: "100.00",
        100: "91.40",
        200: "88.40",
        300: "82.50",
        400: "76.70",
        500: "70.90",
        600: "62.20",
      },
      Telmed: {
        0: "99.50",
        100: "93.70",
        200: "87.90",
        300: "82.00",
        400: "76.20",
        500: "72.60",
        600: "64.50",
      },
      HMO: {
        0: "97.20",
        100: "91.40",
        200: "85.60",
        300: "79.70",
        400: "73.90",
        500: "68.10",
        600: "62.20",
      },
      Standard: {
        0: "108.80",
        100: "106.00",
        200: "97.10",
        300: "91.30",
        400: "85.50",
        500: "79.60",
        600: "73.80",
      },
      maxPrice: {
        0: "160.80",
        100: "150.30",
        200: "144.50",
        300: "138.60",
        400: "132.80",
        500: "127.00",
        600: "121.10",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "296.50",
        500: "285.70",
        1000: "258.60",
        1500: "231.40",
        2000: "205.50",
        2500: "178.20",
      },
      Telmed: {
        300: "291.90",
        500: "281.00",
        1000: "253.70",
        1500: "226.40",
        2000: "199.20",
        2500: "171.90",
      },
      HMO: {
        300: "288.30",
        500: "277.50",
        1000: "250.30",
        1500: "223.20",
        2000: "196.10",
        2500: "168.80",
      },
      Standard: {
        300: "310.50",
        500: "299.60",
        1000: "272.30",
        1500: "245.00",
        2000: "217.80",
        2500: "190.50",
      },
      maxPrice: {
        300: "490.80",
        500: "480.10",
        1000: "453.10",
        1500: "425.70",
        2000: "398.70",
        2500: "371.80",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "395.30",
        500: "384.50",
        1000: "357.40",
        1500: "330.20",
        2000: "303.10",
        2500: "276.00",
      },
      Telmed: {
        300: "399.00",
        500: "388.30",
        1000: "361.40",
        1500: "333.90",
        2000: "306.90",
        2500: "280.00",
      },
      HMO: {
        300: "384.20",
        500: "373.40",
        1000: "346.30",
        1500: "319.10",
        2000: "292.10",
        2500: "264.90",
      },
      Standard: {
        300: "444.10",
        500: "433.30",
        1000: "406.20",
        1500: "379.00",
        2000: "352.00",
        2500: "324.80",
      },
      maxPrice: {
        300: "564.40",
        500: "553.70",
        1000: "526.70",
        1500: "499.30",
        2000: "472.30",
        2500: "445.30",
      },
    },
  },
  ZG: {
    KINDER: {
      Hausarzt: {
        0: "72.90",
        100: "67.10",
        200: "61.30",
        300: "55.40",
        400: "49.60",
        500: "44.00",
        600: "43.10",
      },
      Telmed: {
        0: "74.30",
        100: "68.90",
        200: "63.10",
        300: "57.20",
        400: "51.40",
        500: "47.50",
        600: "42.50",
      },
      HMO: {
        0: "72.90",
        100: "67.10",
        200: "61.30",
        300: "55.40",
        400: "49.60",
        500: "44.00",
        600: "43.10",
      },
      Standard: {
        0: "80.80",
        100: "76.00",
        200: "69.50",
        300: "63.30",
        400: "57.50",
        500: "51.60",
        600: "46.10",
      },
      maxPrice: {
        0: "125.70",
        100: "109.70",
        200: "114.00",
        300: "98.00",
        400: "102.30",
        500: "86.40",
        600: "90.70",
      },
    },
    JUGENDLICHE: {
      Hausarzt: {
        300: "221.30",
        500: "210.40",
        1000: "183.10",
        1500: "155.80",
        2000: "129.20",
        2500: "123.20",
      },
      Telmed: {
        300: "216.70",
        500: "205.80",
        1000: "178.50",
        1500: "151.20",
        2000: "124.60",
        2500: "123.20",
      },
      HMO: {
        300: "214.40",
        500: "203.50",
        1000: "176.20",
        1500: "148.9",
        2000: "123.20",
        2500: "123.20",
      },
      Standard: {
        300: "230.40",
        500: "219.60",
        1000: "192.30",
        1500: "165.00",
        2000: "138.30",
        2500: "123.40",
      },
      maxPrice: {
        300: "398.40",
        500: "387.50",
        1000: "360.40",
        1500: "333.30",
        2000: "306.10",
        2500: "279.00",
      },
    },
    ERWACHSENE: {
      Hausarzt: {
        300: "304.90",
        500: "294.10",
        1000: "267.00",
        1500: "239.80",
        2000: "212.70",
        2500: "185.60",
      },
      Telmed: {
        300: "313.10",
        500: "302.30",
        1000: "275.10",
        1500: "248.00",
        2000: "220.90",
        2500: "196.60",
      },
      HMO: {
        300: "301.50",
        500: "290.80",
        1000: "263.60",
        1500: "236.40",
        2000: "209.40",
        2500: "189.50",
      },
      Standard: {
        300: "342.60",
        500: "331.80",
        1000: "304.60",
        1500: "277.50",
        2000: "250.40",
        2500: "223.20",
      },
      maxPrice: {
        300: "531.10",
        500: "520.30",
        1000: "493.10",
        1500: "466.00",
        2000: "438.90",
        2500: "411.80",
      },
    },
  },
}
